export const CKeyWordsPages = {
  project:"proyectos exclusivos, proyecto exclusivo en construcción, proyecto exclusivo construido, proyectos exclusivos remax rd, casas en venta, villas en venta, townhouse en venta, apartamentos en venta, oficinas en venta, almacén en venta, proyectos exclusivos de remax república dominicana",
  join:"conviértete en agente inmobiliario remax, únete a remax república dominicana, agente inmobiliario remax, agencia inmobiliaria, agente inmobiliario, agente Remax",
  page404: "pagina no encontrada",
  home: "inmueble, remax rd, remax república dominicana, alquilar una casa, comprar una casa, comercialización de inmuebles, proyectos en construcción, beneficios de comprar en planos, préstamos hipotecarios, préstamos hipotecarios en república dominicana, franquicia remax, Propiedades destacadas, propiedades de Remax RD, propiedades de remax república dominicana, agente inmobliario, agencia inmobiliaria, propiedades en República Dominicana, alquiler apartamento, bienes raices santo domingo, departamentos en alquiler santo domingo, proyectos de apartamentos en punta cana, alquilar casa, apartamentos en santo domingo este, apartamentos en bavaro, apartamento en venta punta cana, apartamentos en rd, apartamentos en alquiler santo domingo este, proyectos de apartamentos económicos en santo domingo, apartamentos en venta san isidro, apartamentos en venta republica dominicana, apartamentos en alquiler en santo domingo distrito nacional, apartamento en alquiler, casas en alquiler en república dominicana, proyectos de apartamentos en santo domingo distrito nacional, venta de propiedades en republica dominicana, casas rd, venta de casas en santo domingo, apartamento en el millon, alquiler de apartamentos en santo domingo distrito nacional, comprar apartamento en santo domingo, compra de apartamentos en santo domingo, apartamentos en la romana",
  homeCollections: "Remax collection, propiedades de lujo, propiedades exclusivas, propiedades premium, propiedades collection",
  mortgageLoad: "préstamo hipotecario, Calculadora de prestamos, cuota de préstamos hipotecarios, formulario de prestamos",
  iWantToRent:"casas en alquiler, villas en alquiler, townhouse en alquiler, apartamento en alquiler, oficinas en alquiler, almacén en alquiler, villas en alquiler, alquiler de aparta estudio, proyectos exclusivos de remax república dominicana",
  iWantToBuy:"proyectos exclusivos, proyecto exclusivo en construcción, proyecto exclusivo construido, proyectos exclusivos remax rd, casas en venta, villas en venta, townhouse en venta, apartamentos en venta, oficinas en venta, almacén en venta, proyectos exclusivos de remax república dominicana, compra inmobiliaria, adquir propiedad inmobiliaria, inversion inmobiliaria en república dominicana, inversión inmobiliaria"
};
