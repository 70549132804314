import Link from "next/link";
import React from "react";
import Button from "shared/components/Button";
import Typography from "shared/components/Typography";
import { CPagesPath } from "shared/models/const/pages-path";
import { useTranslations } from "next-intl";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";

function ProyectHomeSection() {
  const translation = useTranslations("home.projects");
  const referer =  useGetPersistParams({
    useConque:"&"
  })
  return (
    <>
      <article className="projects">
        <section className="projects__details">
          <Typography color="--white" fontSize="--fs-title" fontWeight={500}>
            {translation("title")}
          </Typography>
          <Typography
            color="--white"
            fontSize="--fs-body-small"
            fontWeight={400}
            className="m-t-15"
          >
            {translation("subtitle")}
          </Typography>
          <Typography
            color="--white"
            fontSize="--fs-sub-title-small"
            fontWeight={500}
            className="p-v-15"
          >
            {translation("benefits")}
          </Typography>
          <ul className="projects__list p-l-10">
            <Typography
              color="--white"
              fontSize="--fs-body-small"
              component="li"
              className="m-v-5 projects__list_item"
            >
              {translation("benefitsOne")}
            </Typography>
            <Typography
              color="--white"
              fontSize="--fs-body-small"
              component="li"
              className="m-v-5 projects__list_item"
            >
              {translation("benefitsTwo")}
            </Typography>
            <Typography
              color="--white"
              fontSize="--fs-body-small"
              component="li"
              className="m-v-5 projects__list_item"
            >
              {translation("benefitsThree")}
            </Typography>
            <Typography
              color="--white"
              fontSize="--fs-body-small"
              component="li"
              className="m-v-5 projects__list_item"
            >
              {translation("benefitsFour")}
            </Typography>
            <Typography
              color="--white"
              fontSize="--fs-body-small"
              component="li"
              className="m-v-5 projects__list_item"
            >
              {translation("benefitsFive")}
            </Typography>
          </ul>
          <div className="projects__link_content">
            <Link
              href={
                CPagesPath.es.project +
                "?businessTypes=sale&typeProperty[]=project" +
                referer
              }
              passHref
            >
              <Button component="a">{translation("seeProject")}</Button>
            </Link>
          </div>
        </section>
      </article>

      <style jsx>
        {`
          /* PROJECTS */
          .projects {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            width: 100%;
            height: 800px;
            background-image: url(/images/image-optimize/plan-contructor.webp);
          }
          .projects__details {
            width: 50%;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: #1f3564;
            border-radius: 100px 0 0 0;
            padding: 50px 100px;
          }
      
          .franchise__link,
          .projects__title,
          .projects__text,
          .projects__list_item,
          .projects__list_title,
          .projects__link {
            color: #fff;
          }
          .projects__list_item{
            line-height: 25px;
            font-weight: 400;
            font-size:0.938rem;
            list-style-type: " • ";

          }
          .projects__title {
            text-align: left  !important;
            font-weight: 500;
            margin-bottom: 30px;
            font-size: 1.938rem !important
           
          }
          .projects__text {
            margin-bottom: 30px;
          }
          .projects__list_title {
              white-space: nowrap;
              line-height: 10px;
              color: white;
              margin: 43px 0px!important;
              font-weight: 700;
              letter-spacing: 0px;
              text-align: left;
          }
          .projects__list {
            list-style-type: " • ";
            margin-bottom: 40px;
            max-width: 600px;
          }
          .lending__btn_link:hover,
          .lending__btn_link:active,
          .lending__btn_link:visited,
          .lending__btn_link,
          .franchise__btn_link:hover,
          .franchise__btn_link:active,
          .franchise__btn_link:visited,
          .franchise__btn_link,
          .projects__link:hover,
          .projects__link:active,
          .projects__link:visited,
          .projects__link {
            background-color: #e92027;
            text-decoration: none;
            padding: 10px 20px;
            border-radius: 50px;
            font-weight: 700;
          }
          .projects__link_content{
            text-align:left !important;
          }

          /* FIN PROYJECTS */

          @media screen and (max-width: 1200px) {
              .projects__details{
                width: 55%;
                background-color: var(--color-blue-main);
                -webkit-border-radius: 100px 0 0 0;
                -moz-border-radius: 100px 0 0 0;
                border-radius: 100px 0 0 0;
                padding: 38px 58px ;
              }
              .projects__title{
                text-align: left!important;
                font-weight: 500;
                font-size: 1.938rem!important;
                margin-bottom: 22px !important;
              }
              .projects__text{}
              .projects__list_title{
                line-height: 18px;
                color: white;
                margin: 33px 0px!important;
                font-weight: 700;
                letter-spacing: 0px;
                text-align: left;
              }
              .projects__list_item{
                font-size:0.875rem !important
              }
              .projects__link{
                text-align: center;
              }
              .projects__link_content:{
                display: inline-block;
                margin: 0 auto;
              }
          }
          @media screen and (max-width: 991px) {
            .projects {
              background-image: none !important;
              background-color: #1f3564;
              height: auto;
              padding:0px 20px
            }
            .projects__title{
              text-align: center!important;
              font-weight: 500;
              font-size: 1.656rem!important;
              margin-bottom: 22px !important;
            }
            .projects__details {
              position: static;
              width: 100%;
              border-radius: 0;
              max-width: 720px;
              padding-left: 10px;
              padding-right: 10px;
              padding-top: 20px;
              margin-left: auto;
              margin-right: auto;
            }

            .projects__link_content{
              text-align: center !important;
            }
            
          }
         

          @media screen and (max-width: 767px) {
            .finder-text{
              text-align: center ;
              white-space: nowrap;
            }
            .projects {
              background-image: none !important;
              background-color: #1f3564;
              height: auto;
              padding: 0 20px 40px;
            }
            .projects__details {
              max-width: 540px;
            }
             .projects__list_title:{
              line-height: 17px ;
            }
        
          @media screen and (max-width: 575px) {
            .projects__list {
              padding-left:12px
            }
          }
          @media screen and (max-width: 385px) {}
        `}
      </style>
    </>
  );
}

export default ProyectHomeSection;
