import React from "react";
import MuiTypography, { TypographyProps } from "@mui/material/Typography";
import { css } from "@emotion/react";

/**
 *
 * @description Typography component
 * default styles are
 * --font-size body medium,
 *  color --color-gray-01
 *  and weight 400
 * @returns
 */
const Typography: React.FC<TypographyInterface> = (props: TypographyInterface) => {
  const styles = {
    root: css({
      fontFamily: "var(--font-family)",
      fontSize: props.fontSize
        ? `var(${props.fontSize})`
        : `var(--fs-body-medium)`,
      color: `var(${props.color})` ?? `var(--color-gray-01)`,
      fontWeight: props.fontWeight ?? 400,
     
    }),
  };
  return (
    <MuiTypography css={styles.root} {...props}>
      
      {props.children}
    </MuiTypography>
  );
};

export default Typography;

export interface TypographyInterface extends TypographyProps {
  color?:
    | "--color-blue-main"
    | "--color-blue-medley"
    | "--color-blue-light"
    | "--color-green-success"
    | "--color-secondary"
    | "--color-red-error"
    | "--color-background"
    | "--color-gray-01"
    | "--color-gray-02"
    | "--white";
  fontSize?:
    | undefined
    | "--fs-title"
    | "--fs-sub-title-large"
    | "--fs-sub-title-medium"
    | "--fs-sub-title-small"
    | "--fs-body-large"
    | "--fs-body-medium"
    | "--fs-body-small"
    | "--fs-body-smaller"
    | "--fs-text-indice";
  fontWeight?: 100 | 400 | 500 | 600 | 700;
  component?: any;
}
