import React from "react";
import CustomCarrusel from "shared/components/custom-carrusel";
import { IArticlesCardComponent } from "shared/models/interfaces/components/article-card.interface";
import ArticleCard from "shared/components/article-card";
import { TitleThickThin } from "shared/components/titles";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";

function ArticlesBlogHomeSection() {
  const titles = useTranslations("home.recentArticles");

  const articlesTitle = titles("title") || "";

  const [title, ...rest] = articlesTitle.split(" ");

  return (
    <>
      <ContentSection>
        <TitleThickThin
          thickTitle={title}
          thinTitle={rest.join(" ")}
          align="center"
        />
        <div className="container">
          <CustomCarrusel>
            {articles.map((article, i) => (
              <Wrapper key={i}>
                <ArticleCard {...article}></ArticleCard>
              </Wrapper>
            ))}
          </CustomCarrusel>
        </div>
      </ContentSection>
    </>
  );
}

export default ArticlesBlogHomeSection;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 5px;
`;

const ContentSection = styled.section`
  background-color: #fff;
  padding: 80px 0 20px;
  @media screen and (max-width: 991px) {
    padding-top: 40px;
  }
`;

const articles: IArticlesCardComponent[] = [
  {
    title: "¿Cómo saber qué casa comprar?",
    extract: `Ponemos a tu disposición documentación verificada y validada por profesionales del sector inmobiliario, que te ayudará a saber qué casa comprar.`,
    link: "https://blog.remaxrd.com/finanzas/como-saber-que-casa-comprar/",
    img: "https://blog.remaxrd.com/wp-content/uploads/2022/07/Como-saber-que-casa-comprar.png",
  },
  {
    title:
      "¿Cuáles son los requisitos para recibir el Bono de Primera Vivienda?",
    extract: `Te explicamos los requisitos a completar, para recibir el bono de primera vivienda y puedas cumplir una de las metas más importantes de tu vida.`,
    link: "https://blog.remaxrd.com/sector-inmobiliario/cuales-son-los-requisitos-para-recibir-el-bono-de-primera-vivienda/",
    img: "https://blog.remaxrd.com/wp-content/uploads/2022/06/portada-blog.png",
  },
  {
    title: "¿Cómo conseguir un préstamo hipotecario? Te mostramos",
    extract: `Te mostramos los requisitos y recomendaciones para que puedas conseguir la aprobación de un préstamo hipotecario, de forma fácil y segura.`,
    link: "https://blog.remaxrd.com/sector-inmobiliario/como-conseguir-un-prestamo-hipotecario-te-mostramos/",
    img: "https://blog.remaxrd.com/wp-content/uploads/2022/07/%C2%BFComo-conseguir-un-prestamo-hipotecario.png",
  },
  {
    title: "¿Cómo mejorar tu historial crediticio para financiar tu casa?",
    extract: `Para poder conseguir un préstamo, hay diversos factores que se toman en cuenta. ¿El más importante? Mejorar tu historial crediticio o score crediticio.`,
    link: "https://blog.remaxrd.com/finanzas/como-mejorar-tu-historial-crediticio-para-financiar-tu-casa/",
    img: "https://blog.remaxrd.com/wp-content/uploads/2022/07/portada-blog-historial-crediticio.png",
  },
];
