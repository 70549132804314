import CustomCarrusel from "shared/components/custom-carrusel";
import { TitleThickThin } from "shared/components/titles";
import React from "react";
import Link from "next/link";
import { CPagesPath } from "shared/models/const/pages-path";
import { useTranslations } from "next-intl";

function CitiesHomeSection() {
  const translation = useTranslations("home.cities");

  const citiesTitles = translation("title");

  const [title, ...rest] = citiesTitles.split(" ");

  return (
    <>
      <article className="cities">
        <div className="container cities__container">
          <section className="cities__left_side">
            <img
              src="/images/image-optimize/collage.webp"
              alt="Agentes de Remax RD"
              className="cities__left_side__image"
            />
          </section>
          <section className="cities__right_side">
            <TitleThickThin
              thickTitle={title}
              thinTitle={rest.join(" ")}
              align="center"
            />
            <div className="cities__slider">
              <CustomCarrusel>
                {cities.map((city, i) => (
                  <Link href={city.link} key={i} passHref>
                    <a className="content_cities">
                      <div className="cities__card">
                        <div className="cities__link">
                          <img
                            src={city.image}
                            alt={city.label}
                            className="cities__card_image"
                          />
                          <h3 className="cities__card_title">
                            {city.label.toUpperCase()}
                          </h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                ))}
              </CustomCarrusel>
            </div>
          </section>
        </div>
      </article>

      <style jsx>
        {`
          /* CITIES */
          .content_cities {
            display: flex !important;
            justify-content: center;
          }
          .cities {
            width: 100%;
            background-color: #fff;
            padding: 150px 0 100px;
          }
          .cities__container {
            display: flex;
            align-items: center;
          }
          .cities__left_side {
            width: 40%;
          }
          .cities__left_side__image {
            width: 100%;
          }
          .cities__right_side {
            width: 60%;
          }
          .cities__title {
            font-size: 1.375rem !important;
            font-weight: 500 !important;
          }
          .cities__title_light {
            font-size: 1.375rem !important;
            font-weight: 400 !important;
          }
          .cities__card {
            max-width: 300px;
            display: inline-block;
            margin: 0 10px 10px;
            margin: auto;
          }
          .cities__card_image {
            max-width: 135px;
            width: 100%;
            height: 135px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            margin-bottom: 10px;
          }
          .cities__link {
            display: inline-block;
            text-align: center;
            text-decoration: none;
          }
          .cities__card_title {
            color: #808080;
            font-weight: 500;
            font-size: 0.813rem !important;
          }
          /* FIN CITIES*/

          @media screen and (max-width: 1300px) {
          }
          @media screen and (max-width: 991px) {
            .cities__left_side {
              display: none;
            }
            .cities__right_side {
              width: 100%;
            }
          }

          @media screen and (max-width: 767px) {
            .cities {
              width: 100%;
              background-color: #fff;
              padding: 70px 0 74px;
            }
          }
          @media screen and (max-width: 575px) {
          }
          @media screen and (max-width: 385px) {
          }
        `}
      </style>
    </>
  );
}

export default CitiesHomeSection;

const cities = [
  {
    label: "La romana",
    image: "/images/la_romana_remax_rd.png",
    link:
      CPagesPath.es.properties +
      `?businessTypes=sale&currencyType=us&locations[]=id-60%26description-LA%20ROMANA%26`,
  },
  {
    label: "Punta Cana",
    image: "/images/punta_cana_remax_rd.png",
    link:
      CPagesPath.es.properties +
      `?businessTypes=sale&currencyType=us&locations[]=id-434%26description-PUNTA%20CANA%26`,
  },
  {
    label: "Santo Domingo",
    image: "/images/santo_domingo_remax_rd.png",
    link:
      CPagesPath.es.properties +
      `?businessTypes=sale&currencyType=us&locations[]=id-1%26description-SANTO%20DOMINGO%20DE%20GUZMÁN%26`,
  },
  {
    label: "Samaná",
    image: "/images/samana_remax_rd.png",
    link:
      CPagesPath.es.properties +
      `?businessTypes=sale&currencyType=us&locations[]=id-93%26description-SAMANÁ%26`,
  },
  {
    label: "Santiago",
    image: "/images/santiago_remax_rd.png",
    link:
      CPagesPath.es.properties +
      `?businessTypes=sale&currencyType=us&locations[]=id-120%26description-SANTIAGO%26`,
  },
  {
    label: "Puerto Plata",
    image: "/images/puerto_plata_remax_rd.png",
    link:
      CPagesPath.es.properties +
      `?businessTypes=sale&currencyType=us&locations[]=id-81%26description-PUERTO%20PLATA%26`,
  },
];
