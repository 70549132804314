import styled from "@emotion/styled";
import { useWidth } from "custom-hooks/use-width.hook";
import Link from "next/link";
import React from "react";
import Button from "shared/components/Button";
import { TitleThickThin } from "shared/components/titles";
import Typography from "shared/components/Typography";
import { CPagesPath } from "shared/models/const/pages-path";
import { useTranslations } from "next-intl";

function FranchisesHomeSection() {
  const width = useWidth();
  const translation = useTranslations("home.franchise");

  const franchiseTitle = translation("title") || "";

  const [title, ...rest] = franchiseTitle.split(" ");

  return (
    <>
      <article className="franchise">
        <div className="">
          <div className="franchise__details">
            <TitleThickThin
              thickTitle={title}
              thinTitle={rest.join(" ")}
              align={["xs", "sm"].includes(width) ? "center" : "left"}
              mb="30px"
            />
            <TextInfo>{translation("network")}</TextInfo>

            <TextInfo>{translation("advantage")}</TextInfo>

            <TextInfo>{translation("develop")}</TextInfo>
            <ContentBtn className="franchise__btn">
              <Link href={CPagesPath.es.franchises}>
                <Button component="a">{translation("openFranchise")}</Button>
              </Link>
              <Link href={CPagesPath.es.join}>
                <Button component="a" bg="--color-blue-light">
                  {translation("joinUs")}
                </Button>
              </Link>
            </ContentBtn>
          </div>
        </div>
      </article>

      <style jsx>
        {`
          /* FRANQUICIAS */
          .franchise {
            padding-top: 200px;
            height: 800px;
            background-color: #fff;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("/images/image-optimize/franchise-agent-women.webp");
          }
          .franchise__details {
            padding: 0px 107px;
            max-width: 618px;
            margin-left: auto;
            font-weight: 400;
            color: #8c8c8c;
            font-size: 0.938rem;
          }

          /* FIN FRANQUICIAS */
          @media screen and (max-width: 1300px) {
          }

          @media screen and (max-width: 1200px) {
            .franchise__details {
              padding: 0px 55px;
              max-width: 415px;
            }
          }
          @media screen and (max-width: 991px) {
            .franchise {
              background-image: none !important;
              padding-top: 100px;
              padding-bottom: 40px;
              height: auto;
            }
            .franchise__text {
              text-align: center;
            }
            .franchise__title {
              text-align: center !important;
            }
            .franchise__details {
              margin-right: auto;
              padding: 0px 0px;
            }
          }

          @media screen and (max-width: 767px) {
            .franchise__details {
              padding: 0px 20px;
            }
          }

          @media screen and (max-width: 575px) {
            .franchise__details {
              padding: 0px 20px;
            }
          }
          @media screen and (max-width: 385px) {
            .franchise__details {
              padding: 0px 20px;
            }
          }
        `}
      </style>
    </>
  );
}

export default FranchisesHomeSection;

const TextInfo = styled.p`
  text-shadow: 1px 0px 0.5px #fffa;
  color: var(--color-gray-02);
  font-weight: 500;
  font-size: var(--fs-body-small);
  margin: 15px 0px;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
`;

const ContentBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & a {
    min-width: 260px;
    margin: 10px 0px;
  }
`;
