import Link from "next/link";
import React from "react";
import Button from "shared/components/Button";
import { CPagesPath } from "shared/models/const/pages-path";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";

function LendingHomeSection() {
  const translation = useTranslations("home.mortgage");

  const lendingTitle = translation("title") || "";

  const [title, ...rest] = lendingTitle.split(" ");

  return (
    <LendingHomeSectionContent>
      <SpaceWhite />
      <ContentTitle>
        <SubContentTitle>
          <TitleLabel>
            {title}
            <br />
            <span>{rest.join(" ")}</span>
          </TitleLabel>
        </SubContentTitle>
      </ContentTitle>
      <ContentBody>
        <SubContentBody>
          <p>{translation("description")}</p>
          <br />
          <p>{translation("alliance")}</p>
          <ContentButton>
            <Link href={CPagesPath.es.loan_form + "#loanForm"} passHref>
              <Button component={"a"}>{translation("applyForLoan")}</Button>
            </Link>
          </ContentButton>
        </SubContentBody>
      </ContentBody>
      <WRapperCurveContent />
      <ContentWrapper>
        <WRapperImage
          src="/images/image-optimize/black-couple.webp"
          width={100}
          height={200}
          alt="Pareja que adquiriendo una casa "
        />
      </ContentWrapper>
    </LendingHomeSectionContent>
  );
}

export default LendingHomeSection;

const LendingHomeSectionContent = styled.div`
  position: relative;
  width: 100%;
  background-image: white;
  height: auto;
`;

const SpaceWhite = styled.div`
  width: 100%;
  background: white;
  height: 100px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const ContentTitle = styled.div`
  width: 100%;
  background-color: var(--color-blue-main);
  height: 180px;
  display: flex;
  align-items: center;
`;

const SubContentTitle = styled.div`
  width: 60%;
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 900px) {
    width: 70%;
    margin: 0 auto;
  }
  @media screen and (max-width: 400px) {
    width: 90%;
    margin: 0 auto;
    margin-left: 0%;
  }
`;

const TitleLabel = styled.h2`
  margin: 0px !important;
  color: white;
  font-weight: 600;
  font-size: 40px;
  span {
    font-weight: 400;
    margin-left: 100px;
    font-size: 30px;
  }

  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 400px) {
    margin: 0px !important;

    font-size: 30px;
    span {
      font-size: 20px;
    }
  }
`;

const ContentBody = styled.div`
  width: 100%;
  height: 360px;
  background-color: #7b8aab;
  display: flex;

  align-items: center;
`;

const SubContentBody = styled.div`
  width: 60%;
  padding-left: 100px;
  p {
    width: 55%;
    margin: 0 auto;
    color: #fff;
    font-size: var(--fs-body-medium);
    font-family: var(--font-family);
    font-weight: 400;
    line-height: 18px;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 0px;
    p {
      width: 70%;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    p {
      width: 70%;
    }
  }
`;

const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
`;

const WRapperCurveContent = styled.div`
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  background-color: #111e38;
  border-radius: 350px 0 0 0;
  width: 35%;
  height: 100%;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: red;
  float: right;
`;

const WRapperImage = styled.img`
  position: absolute;
  z-index: 11;
  right: 0;
  bottom: 0;
  width: 525px;
  height: 702px;
  margin-right: 14%;
  transform: translate(23px, 61px);
  @media screen and (max-width: 1285px) {
    margin-right: 8%;
  }
  @media screen and (max-width: 1135px) {
    margin-right: 6%;
  }
  @media screen and (max-width: 1100px) {
    margin-right: 3%;
  }
  @media screen and (max-width: 1100px) {
    margin-right: 0%;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
