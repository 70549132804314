import React from "react";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
function Cover({ children }: any) {
  const translation = useTranslations("home");

  return (
    <>
      <CoverContainer>
        <CoverBackground />
        <CoverContainerImage>
          <Title>RE/MAX República Dominicana</Title>
          <TitleSubTitle>
            {translation.rich("title", {
              span: (chunks) => <span>{chunks}</span>,
            })}
          </TitleSubTitle>
          {children}
        </CoverContainerImage>
      </CoverContainer>
    </>
  );
}

export default Cover;

const CoverContainer = styled.section`
  position: relative;
  padding: 40px 0 0 0;
  margin-bottom: 0px;
`;

const CoverBackground = styled.section`
  background-color: #aa9d89;
  width: 100%;
  height: 450px;
  border-radius: 0 0 0 200px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 991px) {
  }
  @media screen and (max-width: 767px) {
  }
  @media screen and (max-width: 590px) {
    border-radius: 0 0 0 0;
    height: 360px;
    // background-image: url("/images/image-optimize/living.webp");
    object-fit: contain;
  }
  @media screen and (max-width: 450px) {
  }
`;

const CoverContainerImage = styled.section`
  width: 85%;
  background-size: cover;
  background-position: center;
  height: 600px;
  border-radius: 60px;
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-content: flex-start;
  padding-top: 120px;
  background-image: url("/images/image-optimize/living.webp");
  margin: 0 auto;
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 991px) {
  }
  @media screen and (max-width: 767px) {
    height: 600px;
  }
  @media screen and (max-width: 667px) {
    padding-top: 80px;
    height: 500px;
  }
  @media screen and (max-width: 590px) {
    // background-image: none;
    width: 100%;
    padding-top: 60px;
    height: 380px;
  }
  @media screen and (max-width: 450px) {
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 991px) {
  }
  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 667px) {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 500px) {
  }
  @media screen and (max-width: 450px) {
  }
`;

const TitleSubTitle = styled.p`
  color: #fff;
  font-size: 1.6rem;
  margin-bottom: 86px;
  text-align: center;
  font-weight: 400;
  span {
    font-family: "Zapfino", sans-serif;
    color: #fff;
    font-size: 1.4rem;
    position: relative;
    bottom: -20px;
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 991px) {
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 46px;
    span {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 500px) {
  }
  @media screen and (max-width: 450px) {
  }
`;
