import Link from "next/link";
import React from "react";
import { IArticlesCardComponent } from "shared/models/interfaces/components/article-card.interface";
import styled from "@emotion/styled";
import Image from 'next/image'
function ArticleCard(props: IArticlesCardComponent) {
  return (
    <Link href={props.link} passHref>
      <ArticlesCard target="_blank">
        <ImageContent
          src={props.img}
          width={300}
          height={170}
          alt="Miniatura del articulo"
        />

        <BodyContainer>
          <h4>{props.title}</h4>
          <p>{props.extract}</p>
        </BodyContainer>
      </ArticlesCard>
    </Link>
  );
}

export default ArticleCard;

const ArticlesCard = styled.a`
  margin: auto;
  width: 100%;
  text-decoration: none;
  height: 60vh;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  padding: 0px 20px;
  @media screen and (max-width: 1210px) {
  }
  @media screen and (max-width: 500px) {
    height: auto;
  }
  @media screen and (max-width: 400px) {
    padding: 0px 30px;
  }
`;

const ImageContent = styled(Image)`
  display: block;
`;

const BodyContainer = styled.div`
  h4 {
    color: var(--color-blue-main);
    fontweight: 600;
    fontsize: var(--fs-body-medium);
    padding: 15px 0px;
    min-height: 20px;
    // white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
  }

  p {
    color: var(--color-gray-02);
    fontweight: 400;
    fontsize: var(--fs-body-small);
    text-align: left;
    padding-top: 10px;
  }
`;
